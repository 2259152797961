import React from "react"
// Components
import Navigation from "./nav"
import Footer from "./footer"
// Styles
import "./styles/layout.css"
import "../styles/global.scss"

const Layout = ({ children }) => {
  return (
    <div className="page-container">
      <div className="page-content">{children}</div>
    </div>
  )
}

export default Layout
